const maindata = {
  data: [
    {
      bg_photo: "https://files.skg.com/skg/2025-02-14/1edf22ed-7153-43dc-a275-883093ca99bf.png", // 1
      url: "/",
    },
    {
      bg_photo: "https://files.skg.com/skg/2025-02-14/4c6184c7-c10f-452b-9391-a54ec301b7e2.png", // 2
      url: "/",
    },
    {
      bg_photo: "https://files.skg.com/skg/2025-02-14/160042e0-97fa-44ec-8021-769a26810694.png", // 3
      url: "/",
    },
    {
      bg_photo: "https://files.skg.com/skg/2025-02-14/b2e92b53-9643-4b48-ae35-7b4942a8aed4.png", // 4
      url: "/",
    },
    {
      bg_photo: "https://files.skg.com/skg/2025-02-14/2bfb63b7-24eb-4baa-8f4b-fba752bb35c0.png", // 5
      url: "/",
    }
  ]
};
export default maindata;
