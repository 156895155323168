<template>
  <div class="mySw full">
    <swiper
      class="swiper-container"
      :autoplay="{ delay: 3000, disableOnInteraction: false }"
      :navigation="{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }"
      loop>
      <swiper-slide v-for="(item, i) in dataArr" :key="i">
        <div
          class="swiper-slide"
          :style="{ 'backgroundImage': 'url(' + item.bg_photo + ')' }">
        </div>
      </swiper-slide>
      <!-- 左右切换箭头 -->
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
      <div class="swiper-pagination"></div>
    </swiper>
  </div>
</template>

<script>
import maindata from "../../../static/data/maindata";
import mainpcdata from "../../../static/data/mainpcdata";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

export default {
  name: "smallwiper",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      dataArr: [],
    };
  },
  created() {
    const width = document.body.clientWidth;
    if (width > 600) {
      this.dataArr = mainpcdata.data;
    } else {
      this.dataArr = maindata.data;
    }
  },
};
</script>

<style lang="scss" scoped>
.mySw {
  width: 100%;
  padding-top: 50px;
}

.swiper-container {
  width: 100%;
  --swiper-theme-color: #ff6600;
  --swiper-pagination-color: #42d7c8;
  --swiper-navigation-color: #42d7c8;
  --swiper-navigation-size: 20px;
}

.swiper-slide {
  width: 100vw;
  height: calc(100vh - 20px);
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.swiper-slide img {
  width: 100%;
}

/* 分页样式 */
.swiper-pagination {
  position: absolute;
  width: 100vw;
  height: 2vh;
  bottom: 4vh;
  left: 0vw;
  --swiper-theme-color: #1F6162;
}

/* 左右箭头按钮 */
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  z-index: 10;
  cursor: pointer;
  color: #42d7c8;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
}
.swiper-button-prev {
  left: 20px;
}
.swiper-button-next {
  right: 20px;
}

/* 移动端调整 */
@media screen and (max-width: 600px) {
  .mySw {
    padding-top: 114px;
  }
  .swiper-slide {
    width: 100vw;
    height: 177vw;
    position: relative;
    background: no-repeat 50% 50%;
    background-size: cover;
  }
}
</style>
