<template>
  <div>
    <swiper
      class="swiper-container"
      :navigation="{
        nextEl: '.swiper-view-next',
        prevEl: '.swiper-view-prev',
      }"
      :autoplay="autoplayOption"
      loop
      @swiper="onSwiperInstance"
    >
      <swiper-slide v-for="(item, i) in videoData" :key="i">
        <div class="videoBox">
          <video
            class="indexVideo"
            controls
            :poster="item.poster"
            @play="onVideoPlay"
            @pause="onVideoPause"
            @ended="onVideoPause"
          >
            <source :src="item.src" type="video/mp4" />
          </video>
        </div>
      </swiper-slide>
      <!-- 可根据需要添加导航按钮 -->
      <div class="swiper-button-next swiper-view-next"></div>
      <div class="swiper-button-prev swiper-view-prev"></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

export default {
  name: "videowiper",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      // 保存 swiper 实例
      swiperInstance: null,
      // autoplay 参数，可根据需要调整 delay 等
      autoplayOption: {
        delay: 3000,
        disableOnInteraction: false,
      },
      src: {
        videoImg: require('@/static/img/videoImg.png'),
        videoBottom: require('@/static/img/videoBottom.png'),
      },
      videoData: [
        {
          src: "https://files.skg.com/skg/2025-02-14/cfa538f9-ecc4-4ae7-95f0-125dc4cbe9b2.mp4",
          poster: "https://files.skg.com/skg/2025-02-14/1edf22ed-7153-43dc-a275-883093ca99bf.png",
        },
        {
          src: "https://files.skg.com/skg/2025-02-14/40933e26-4239-42d1-a542-a8137b71e1f1.mp4",
          poster: "https://files.skg.com/skg/2025-02-14/4c6184c7-c10f-452b-9391-a54ec301b7e2.png",
        },
        {
          src: "https://files.skg.com/skg/2025-02-14/e28a7886-03d5-48cb-84a7-52a2f8a0c33f.mp4",
          poster: "https://files.skg.com/skg/2025-02-14/160042e0-97fa-44ec-8021-769a26810694.png",
        },
        {
          src: "https://files.skg.com/skg/2025-02-14/c7da4ed5-67e6-438f-a045-1e974e91e98b.mp4",
          poster: "https://files.skg.com/skg/2025-02-14/b2e92b53-9643-4b48-ae35-7b4942a8aed4.png",
        },
        {
          src: "https://files.skg.com/skg/2025-02-14/c39cd90f-1dde-4d6d-a14b-9aa255fb9b45.mp4",
          poster: "https://files.skg.com/skg/2025-02-14/2bfb63b7-24eb-4baa-8f4b-fba752bb35c0.png",
        }
      ],
    };
  },
  methods: {
    // 获取 swiper 实例后保存，方便后续控制 autoplay
    onSwiperInstance(swiper) {
      this.swiperInstance = swiper;
    },
    // 当视频开始播放时，暂停轮播
    onVideoPlay() {
      if (this.swiperInstance) {
        this.swiperInstance.autoplay.stop();
      }
    },
    // 当视频暂停或结束时，恢复轮播
    onVideoPause() {
      if (this.swiperInstance) {
        this.swiperInstance.autoplay.start();
      }
    }
  },
  created() {
    // 其他初始化逻辑
  },
};
</script>

<style lang="scss" scoped>
.mySw {
  width: 100%;
}
.swiper-container {
  width: 100%;
  --swiper-theme-color: #ff6600;
  --swiper-pagination-color: #42d7c8;
  --swiper-navigation-color: #42d7c8;
  --swiper-navigation-size: 20px;
}
.indexVideo {
  width: 900px;
  height: 500px;
  margin: 0 auto;
}
.videoBox {
  margin: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.indexVideo {
  width: 59vw;
  height: 33vw;
}
.videoBottom {
  width: 59vw;
  height: 3vw;
  margin: 0 auto;
  margin-top: -2.5vw;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .mySw {
    width: 100%;
  }
  .indexVideo {
    width: 59vw;
    height: 33vw;
  }
  .videoBottom {
    width: 59vw;
    height: 3vw;
    margin: 0 auto;
    margin-top: -2vw;
  }
  .videoBottom img {
    height: 6vw;
  }
  .swiper-view-prev::after {
    width: 100px;
    height: 100px;
    margin-left: 60px;
    font-size: 24px;
    font-weight: bold;
  }
  .swiper-view-next::after {
    width: 100px;
    height: 100px;
    margin-right: 60px;
    font-size: 24px;
    font-weight: bold;
  }
}
@media screen and (max-width: 768px) {
  .swiper-view-prev,
  .swiper-view-next {
    display: none;
  }
  .videoBottom {
    width: 59vw;
    height: 3vw;
    margin: 0 auto;
    margin-top: -2vw;
  }
  .videoBottom img {
    width: 95%;
    height: 4vh;
  }
  .indexVideo {
    width: 90vw;
    height: 50vw;
  }
}
</style>
