const maindata = {
  data: [
    {
      bg_photo: "https://files.skg.com/skg/2025-02-14/8803e5c6-4f3b-402b-9dd0-a5ceee66799e.png", // 1
      url: "/",
    },
    {
      bg_photo: "https://files.skg.com/skg/2025-02-14/855f95d1-c728-46c6-bfcc-2685be39183c.png", // 2
      url: "/",
    },
    {
      bg_photo: "https://files.skg.com/skg/2025-02-14/4ab714bc-60c9-481a-b123-c5c4ed559b8f.png",  // 3
      url: "/",
    },
    {
      bg_photo: "https://files.skg.com/skg/2025-02-14/a660afb3-14b4-4727-9dba-2f0b9c341936.png",  // 3
      url: "/",
    },
    {
      bg_photo: "https://files.skg.com/skg/2025-02-14/8b6127e2-cbb5-4e9b-90a1-7b6de41d2a82.png",  // 3
      url: "/",
    }
  ]
};
export default maindata;
